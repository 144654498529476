<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :showLogout="true" :profile="profile"/>
        <div class="container">
            <div class="row">
                <div class="col-2">
                    <Menu selectecMenu="orders"/>
                </div>
                <div class="col-10">
                    <h2><router-link to="/admin/orders"><i class="fa fa-chevron-left"></i> Regresar</router-link></h2>
                    <div class="">
                        <div class="d-flex justify-content-between">
                            <div>Order: {{order.id}}</div>
                            <div><span :class="{'badge':true, 'badge-success': order.status=='paid', 'badge-warning': order.status=='pending'}">{{order.status}}</span></div>
                        </div>

                        <table class="table table-sm">
                            <tbody>
                                <tr>
                                    <th class="bg-dark text-light">Generada el</th>
                                    <td>{{order.created_on | date}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Curso</th>
                                    <td>{{order.course_name}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Cliente</th>
                                    <td>{{order.user_name}}</td>
                                </tr>
                            </tbody>
                        </table>
                        Detalles del pago
                         <table class="table table-sm">
                            <tbody>
                                <tr>
                                    <th class="bg-dark text-light">Pagado el</th>
                                    <td>{{order.payment_date | date}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Forma de pago</th>
                                    <td>{{order.pay_method}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Subtotal</th>
                                    <td class="text-right">${{order.course_price}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Cupón aplicado</th>
                                    <td class="text-right">{{order.coupon}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Cupón descuento</th>
                                    <td class="text-right">${{order.coupon_amount}}</td>
                                </tr>
                                <tr>
                                    <th class="bg-dark text-light">Total</th>
                                    <td class="text-right">${{order.total}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<style scoped>
 .list-group {

     margin:auto;
     float:left;
     padding-top:20px;
    }
    .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
</style>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Menu from '@/modules/Admin/components/menu.vue';
import axios from 'axios';

export default {
  components:{
      Header,
      Footer,
      Menu
  },
  name: 'Profile',
  data() {
      return {
          courses: this.$store.state.userData.courses,
          profile: this.$store.getters.getUser,
          orderId: this.$route.params.orderId,
          order: {}
      }
  },
  mounted() {
      this.loadOrder(this.orderId);
  },
  methods: {
      loadOrder(orderId) {
            axios.get(process.env.VUE_APP_APIURL + 'orders/' + orderId)
            .then((response)=>{
                this.order = response.data.data;
            })
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
  .course:hover{
      opacity:.8
  }
</style>
